// /* eslint-disable max-len */
// import React from 'react';
import React, { useEffect } from "react";
import Layout from "../../components/layoutAr";
import Landing from "../../components/landing/landingUAE-ar";
import Seo from "../../components/seoAr";
import Helmet from "react-helmet";
import { navigate } from "gatsby";
import CampaignClosed from "../../components/campaign-closed/campaignClosedEn";

const IndexPage = (props) => {
  // useEffect(() => {
  //   const dfMessenger = document.querySelector("df-messenger");
  //   const dfMessengerBubble = document.querySelector(
  //     "df-messenger-chat-bubble"
  //   );

  //   setTimeout(() => {
  //     if (dfMessenger && !window.location.href.includes("agegate")) {
  //       // dfMessengerBubble.openChat();
  //       // dfMessenger.renderCustomText("Hello and welcome");
  //       dfMessenger?.startNewSession();
  //       dfMessenger?.sendQuery("hi");
  //     }
  //   }, 2000);
  // }, []);
  //   useEffect(() => {
  //     if (props.path.startsWith("/")) {
  //       document.querySelector("body").classList.add("rightToLeft");
  //       document.querySelector("body").classList.add("arabic-fonts");
  //     }
  //     // navigate("/stores");
  //     if (typeof window !== "undefined") {
  //       window.addEventListener("message", function (event) {
  //         if (event.data.successMessage === "file uploaded") {
  //           setTimeout(() => {
  //             document.querySelector(".uploadForm").classList.remove("show");
  //             document.querySelector("body").classList.remove("overflow-lock");
  //             const dfMessenger = document.querySelector("df-messenger");
  //             dfMessenger.setQueryParameters({
  //               parameters: {
  //                 uploadedFilename: event.data.filename, // Send filename as a parameter
  //               },
  //             });
  //             dfMessenger.renderCustomText(
  //               `
  // Your file had been uploaded successfully
  // تم تحميل ملفك بنجاح.`,
  //               true
  //             );

  //             dfMessenger.renderCustomText(
  //               `
  // Great news! I’ve run the numbers and you’re entitled to a discounted Snickers! could you provide email address to complete the transaction.
  // أخبار رائعة! لقد قمت بتشغيل الأرقام ويحق لك الحصول على سنيكرز بسعر مخفض! هل يمكنك تقديم عنوان البريد الإلكتروني لإكمال المعاملة.`,
  //               true
  //             );
  //             const payload = [
  //               {
  //                 options: [
  //                   {
  //                     mode: "blocking",
  //                     text: "YES نعم",
  //                   },
  //                   {
  //                     mode: "blocking",
  //                     text: "NO كلا",
  //                   },
  //                 ],
  //                 type: "chips",
  //               },
  //             ];
  //             dfMessenger.renderCustomCard(payload);
  //           }, 1000);
  //         }
  //       });
  //     }
  //     document.addEventListener("df-chip-clicked", (event) => {
  //       if (event.detail.text.startsWith("Click here to upload")) {
  //         // document.querySelector("h1").style.color = "red";
  //         document.querySelector(".uploadForm").classList.add("show");
  //         window.scroll(0, 0);
  //         document.querySelector("body").classList.add("overflow-lock");
  //       }
  //     });
  //   }, []);

  return (
    <Layout>
      <Seo title="الصفحة الأساسية | تأمين سنكرس ضدّ الجوع" lang="ar" />
      {/* Campaign is On */}
      {/* <Landing />
      <div className="uploadForm">
        <iframe
          srolling="no"
          src="https://evidence-upload-fuv6o3wcha-uc.a.run.app/"
        ></iframe>
      </div>
      <Helmet>
        {" "}
        <script src="https://www.gstatic.com/dialogflow-console/fast/df-messenger/prod/v1/df-messenger.js"></script>
      </Helmet>
      <df-messenger
        project-id="snickersinsurancechatbot2-ixwh"
        agent-id="05a9b2a2-7e36-45a5-be2d-74ef53573e93"
        language-code="ar"
      >
        <df-messenger-chat-bubble chat-title="Hunger Insurance Chat Bot"></df-messenger-chat-bubble>
      </df-messenger>  */}
      {/* Campaign Closed */}
      <CampaignClosed />
    </Layout>
  );
};

export default IndexPage;
