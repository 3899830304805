import React from "react";

const Copyright = () => {
  return (
    <p className="text-center mb-0">
      Not “insurance” in any real legal sense. &copy;2024 Mars or Affiliates.
      Third part trademarks are property of their respective owners. 
    </p>
  );
};

export default Copyright;
